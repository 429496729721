import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import './index.css';
import './animations.css';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslations from './locales/en.json';
import ptTranslations from './locales/pt.json';

import { initReactI18next } from 'react-i18next';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        resources: {
            en: {
                translation: enTranslations,
            },
            pt: {
                translation: ptTranslations,
            },
        },
    });

const root = createRoot(document.getElementById('root'));
root.render(<App />);
