import React from 'react';
import './sessao.css';

import imgSessao from '../../assets/sessao-img.webp';

import { BotaoWhats } from '../../components';
import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Sessao = () => {
  const { t } = useTranslation();
  // <h2><i>{t('sobre.titulo')}</i></h2>

  return (
    <div className="site__sessao section__padding" id="sessao">
      <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={1}>
        <div className="site__sessao-titulo">
          <h2>{t('sessao.titulo1')}</h2>
          <h2>{t('sessao.titulo2')}</h2>
        </div>
      </AnimationOnScroll>

      <div className="site__sessao-container">
        <AnimationOnScroll animateIn="slide-in-left" animateOnce="true" duration={1}>
          <div className="site__sessao-texto">
            <p>{t('sessao.texto.paragrafo1')}</p>

            <p>{t('sessao.texto.paragrafo2')}</p>

            <p>{t('sessao.texto.paragrafo3')}</p>
          </div>
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="slide-in-right" animateOnce="true" duration={1}>
          <div className="site__sessao-img">
            <img src={imgSessao} alt={t('alt.sessao')} />
          </div>
        </AnimationOnScroll>
      </div>

      <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={1}>
        <div className="site__sessao-btn">
          <BotaoWhats message={t('sessao.botao')} />
        </div>
      </AnimationOnScroll>
    </div>
  )
}

export default Sessao;
