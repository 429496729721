import React from 'react';
import './depoimento.css';

const Depoimento = ({ item, width }) => {
    return (
        <div className="carousel-item" style={{ width: width }}>
            {/* <div></div> */}
            <div className="carousel-item-texto">
                <p><i>{item.texto}</i></p>
            </div>
            
            <div className="carousel-item-nome">
                <p>{item.nome}</p>
            </div>
        </div>
    );
};

export default Depoimento;
