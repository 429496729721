import React from 'react';
import './rodape.css';

import { Contato } from '../../containers';

import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import nomeLogo from '../../assets/logo-nome.webp';
import simboloLogo from '../../assets/logo-simbolo.webp';

const Rodape = () => {
  const { t } = useTranslation();

  return (
    <div className="site__rodape">
      <AnimationOnScroll animateIn="slide-in-fwd-bottom" offset={950} duration={0.7} delay={100} animateOnce="true">
        <div className="site__rodape-container">
          <div className="site__rodape-container_content">
            <div className="site__rodape-containter_content-logoEsq">
              <img src={nomeLogo} alt="Logo" />
            </div>

            <div className="site__rodape-container_content-links">
              <Contato />
            </div>

            <div className="site__rodape-containter_content-logoDir">
              <img src={simboloLogo} alt="Logo" />
            </div>
          </div>
          <div className="site__rodape-container_text">
            <p>{t('footer.copyright')}</p>
            <a href="mailto:guilhermesantosdegodoy@gmail.com">{t('footer.dev')}</a>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  )
}

export default Rodape;
