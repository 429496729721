import React from 'react';
import './expProfDir.css';

import { AnimationOnScroll } from 'react-animation-on-scroll';

const ExpProfDir = ({ titulo, descricao }) => {
  return (
    <AnimationOnScroll animateIn="slide-in-right" duration={1} animateOnce="true">
      <div className="site__expProfDir">
        <div className="site__expProfDir-seta" />
        
        <div className="site__expProfDir-content">
          <h6>{titulo}</h6>
          <p>{descricao}</p>
        </div>
        
      </div>
    </AnimationOnScroll>
  )
}

export default ExpProfDir;
