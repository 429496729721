import React from 'react';
import './sobre.css';

import { BotaoWhats } from '../../components';
import sobreImg from '../../assets/sobre-img.webp';

import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Sobre = () => {
  const { t } = useTranslation();

  return (
    <div className="site__sobre section__padding" id="sobre">
      <AnimationOnScroll animateIn="slide-in-left" duration={1} animateOnce="true">
        <div className="site__sobre-img">
          <img src={sobreImg} alt={t('alt.sobre')} />
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="slide-in-right" duration={1} animateOnce="true">
        <div className="site__sobre-content">
          <div className="site__sobre-content_text">
            <h2>{t('sobre.titulo')}</h2>

            <h6><b>{t('sobre.saudacao')}</b></h6>

            <p>{t('sobre.paragrafo1.parte1')}, <b>{t('sobre.paragrafo1.parte2')}</b>, <b>{t('sobre.paragrafo1.parte3')}</b> {t('sobre.paragrafo1.parte4')} <b>{t('sobre.paragrafo1.parte5')}</b>, {t('sobre.paragrafo1.parte6')}.</p>

            <p>{t('sobre.paragrafo2.parte1')} <b>{t('sobre.paragrafo2.parte2')}</b> {t('sobre.paragrafo2.parte3')} <b>{t('sobre.paragrafo2.parte4')}</b> {t('sobre.paragrafo2.parte5')}, <b>{t('sobre.paragrafo2.parte6')}</b> {t('sobre.paragrafo2.parte7')}</p>

            <p>{t('sobre.conclusao')}</p>
          </div>

          <div className="site__sobre-content_btn">
            <BotaoWhats message={t('sobre.agende')} />
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  )
}

export default Sobre;
