import React from 'react';
import './logo.css';

import simbolo from '../../assets/logo-simbolo.webp';
import nome from '../../assets/logo-nome.webp';

const Logo = () => {
  return (
    <div className="site__logo">
      <div className="site__logo-simbolo">
        <img src={simbolo} alt="Logo" />
      </div>

      <div className="site__logo-nome">
        <img src={nome} alt="Logo" />
      </div>
    </div>
  )
}

export default Logo;
