import React from 'react';
import './especialidade.css';

import { AnimationOnScroll } from 'react-animation-on-scroll';

const Especialidade = ({ titulo, img, alt, topico1, topico2, topico3, topico4, topico5, topico6, topico7 }) => {
  return (
    <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={1}>
      <div className="site__espec">
        <h4>{titulo}</h4>
        <div className="site__espec-img">
          <img src={img} alt={alt} />
          <div className="sombreamento"></div>
        </div>

        <div className="site__espec-topicos">
          <ul>
            <li>{topico1}</li>
            <li>{topico2}</li>
            <li>{topico3}</li>
            <li>{topico4}</li>
            <li>{topico5}</li>
            <li>{topico6}</li>
            <li>{topico7}</li>
            {/* <li>{topico8}</li> */}
          </ul>
        </div>
      </div>
    </AnimationOnScroll>
  )
}

export default Especialidade;
