import React from 'react';
import './experienciaProfissional.css';

import { ExpProDir, ExpProEsq, ExpProEsqAlternate, IconeProfDir, IconeProfEsq } from '../../components';
import meioEsq from '../../assets/expProf-icon-esq.webp';
import meioDir from '../../assets/expProf-icon-dir.webp';

import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';
// import { useInView } from 'react-intersection-observer';
// import { motion } from 'framer-motion';

const ExperienciaProfissional = () => {
  const { t } = useTranslation();

  // const [ref, inView] = useInView({
  //   triggerOnce: true,
  //   threshold: 0.2,
  // });

  return (
    <div className="site__prof section__padding" id="experiencia">
      <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={1}>
        <div className="site__prof-titulo">
          <h1>{t('expProf.titulo')}</h1>
          <h2>{t('expProf.subtitulo')}</h2>
        </div>
      </AnimationOnScroll>

      <div className="linha-vertical" />

      <div className="item-esquerda">
        <div className="card">
          <ExpProEsq titulo={t('expProf.titulo1')} descricao={t('expProf.descricao1')} />
          <IconeProfEsq src={meioEsq} alt={t('alt.iconeProf')} />
        </div>
      </div>

      <div className="item-esquerda-mobile">
        <div className="card">
          <ExpProEsqAlternate titulo={t('expProf.titulo1')} descricao={t('expProf.descricao1')} />
          <IconeProfEsq src={meioEsq} alt={t('alt.iconeProf')} />
        </div>
      </div>

      <div className="item-direita">
        <div className="card">
          <ExpProDir titulo={t('expProf.titulo2')} descricao={t('expProf.descricao2')} />
          <IconeProfDir src={meioDir} alt={t('alt.iconeProf')} />
        </div>
      </div>

      <div className="item-esquerda">
        <div className="card">
          <ExpProEsq titulo={t('expProf.titulo3')} descricao={t('expProf.descricao3')} />
          <IconeProfEsq src={meioEsq} alt={t('alt.iconeProf')} />
        </div>
      </div>

      <div className="item-esquerda-mobile">
        <div className="card">
          <ExpProEsqAlternate titulo={t('expProf.titulo3')} descricao={t('expProf.descricao3')} />
          <IconeProfEsq src={meioEsq} alt={t('alt.iconeProf')} />
        </div>
      </div>

      <div className="item-direita">
        <div className="card">
          <ExpProDir titulo={t('expProf.titulo4')} descricao={t('expProf.descricao4')} />
          <IconeProfDir src={meioDir} alt={t('alt.iconeProf')} />
        </div>
      </div>

      <div className="item-esquerda">
        <div className="card">
          <ExpProEsq titulo={t('expProf.titulo5')} descricao={t('expProf.descricao5')} />
          <IconeProfEsq src={meioEsq} alt={t('alt.iconeProf')} />
        </div>
      </div>

      <div className="item-esquerda-mobile">
        <div className="card">
          <ExpProEsqAlternate titulo={t('expProf.titulo5')} descricao={t('expProf.descricao5')} />
          <IconeProfEsq src={meioEsq} alt={t('alt.iconeProf')} />
        </div>
      </div>

      <div className="item-direita">
        <div className="card">
          <ExpProDir titulo={t('expProf.titulo6')} descricao={t('expProf.descricao6')} />
          <IconeProfDir src={meioDir} alt={t('alt.iconeProf')} />
        </div>
      </div>

      <div className="item-esquerda">
        <div className="card">
          <ExpProEsq titulo={t('expProf.titulo7')} descricao={t('expProf.descricao7')} />
          <IconeProfEsq src={meioEsq} alt={t('alt.iconeProf')} />
        </div>
      </div>

      <div className="item-esquerda-mobile">
        <div className="card">
          <ExpProEsqAlternate titulo={t('expProf.titulo7')} descricao={t('expProf.descricao7')} />
          <IconeProfEsq src={meioEsq} alt={t('alt.iconeProf')} />
        </div>
      </div>

      <div className="item-direita">
        <div className="card">
          <ExpProDir titulo={t('expProf.titulo8')} descricao={t('expProf.descricao8')} />
          <IconeProfDir src={meioDir} alt={t('alt.iconeProf')} />
        </div>
      </div>

      <div className="item-esquerda">
        <div className="card">
          <ExpProEsq titulo={t('expProf.titulo9')} descricao={t('expProf.descricao9')} />
          <IconeProfEsq src={meioEsq} alt={t('alt.iconeProf')} />
        </div>
      </div>

      <div className="item-esquerda-mobile">
        <div className="card">
          <ExpProEsqAlternate titulo={t('expProf.titulo9')} descricao={t('expProf.descricao9')} />
          <IconeProfEsq src={meioEsq} alt={t('alt.iconeProf')} />
        </div>
      </div>

      <div className="item-direita">
        <div className="card">
          <ExpProDir titulo={t('expProf.titulo10')} descricao={t('expProf.descricao10')} />
          <IconeProfDir src={meioDir} alt={t('alt.iconeProf')} />
        </div>
      </div>

      <div className="item-esquerda">
        <div className="card">
          <ExpProEsq titulo={t('expProf.titulo11')} descricao={t('expProf.descricao11')} />
          <IconeProfEsq src={meioEsq} alt={t('alt.iconeProf')} />
        </div>
      </div>

      <div className="item-esquerda-mobile">
        <div className="card">
          <ExpProEsqAlternate titulo={t('expProf.titulo11')} descricao={t('expProf.descricao11')} />
          <IconeProfEsq src={meioEsq} alt={t('alt.iconeProf')} />
        </div>
      </div>
    </div>
  )
}

export default ExperienciaProfissional;
