import React from 'react';
import './menu.css';

import { useTranslation } from 'react-i18next';

const Menu = () => {
  const { t } = useTranslation();

  return (
    <div className="site__menu">
      {/* <p><a href="#home">{t('menu.home')}</a></p> */}
      <p><a href="#sobre">{t('menu.sobre')}</a></p>
      <p><a href="#sessao">{t('menu.sessao')}</a></p>
      <p><a href="#especialidades">{t('menu.especialidades')}</a></p>
      <p><a href="#experiencia">{t('menu.experiencia')}</a></p>
      <p><a href="#faq">{t('menu.faq')}</a></p>
      <p><a href="#depoimentos">{t('menu.depoimentos')}</a></p>
    </div>
  );
};

export default Menu;
