import React from 'react';
import './expAcadDir.css';

import { AnimationOnScroll } from 'react-animation-on-scroll';

const ExpAcadDir = ({ titulo, descricao }) => {
  return (
    <AnimationOnScroll animateIn="slide-in-right" duration={1} animateOnce="true" >
      <div className="site__acadDir">
        <div className="site__acadDir-titulo">
          <h6>{titulo}</h6>
        </div>

        <div className="site__acadDir-desc">
          <p>{descricao}</p>
        </div>
      </div>
    </AnimationOnScroll>
  )
}

export default ExpAcadDir;
