import React from 'react';
import './iconeProfEsq.css';

import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const IconeProfEsq = ({ src, alt }) => {
    const [inView, setInView] = React.useState(false);
    const { ref, inView: refInView } = useInView({
        triggerOnce: true,
    });

    React.useEffect(() => {
        if (refInView) {
            setInView(true);
        }
    }, [refInView]);

    return (
        <motion.img
            className="icone-esq"
            ref={ref}
            src={src}
            alt={alt}
            initial={{ scale: 0 }}
            animate={{ scale: inView ? 1 : 0 }}
            transition={{ type: "tween", duration: 1 }}
        />
    );
};

export default IconeProfEsq;
