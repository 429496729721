import React, { useState } from 'react';
import './depoimentos.css';

import { Depoimento } from '../../components';

import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Depoimentos = () => {
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    {
      nome: t('depoimentos.nome1'),
      texto: t('depoimentos.texto1')
    },
    {
      nome: t('depoimentos.nome2'),
      texto: t('depoimentos.texto2')
    },
    {
      nome: t('depoimentos.nome3'),
      texto: t('depoimentos.texto3')
    },
    {
      nome: t('depoimentos.nome4'),
      texto: t('depoimentos.texto4')
    },
    {
      nome: t('depoimentos.nome5'),
      texto: t('depoimentos.texto5')
    },
    {
      nome: t('depoimentos.nome6'),
      texto: t('depoimentos.texto6')
    },
  ];

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= items.length) {
      newIndex = items.length - 1;
    }

    setActiveIndex(newIndex);
  };

  return (
    <div className="depoimentos-container section__padding" id="depoimentos">
      <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={1}>
        <div className="depoimentos-titulo">
          <h2>{t('depoimentos.titulo')}</h2>
        </div>
      </AnimationOnScroll>

      <div className="carousel">
        <div
          className="inner"
          style={{
            transform: `translate(-${activeIndex * 100}%)`
          }}
        >
          {items.map((item, index) => {
            return <Depoimento item={item} width={"100%"} key={index} />;
          })}
        </div>
      </div>

      <div className="carousel-buttons">
          <button
            className="button-arrow"
            onClick={() => {
              updateIndex(activeIndex - 1);
            }}
          >
            <span className="material-symbols-outlined">arrow_back_ios</span>{" "}
          </button>
          <div className="indicators">
            {items.map((item, index) => {
              return (
                <button
                  className="indicator-buttons"
                  key={index}
                  onClick={() => {
                    updateIndex(index);
                  }}
                >
                  <span
                    className={`material-symbols-outlined ${index === activeIndex
                        ? "indicator-symbol-active"
                        : "indicator-symbol"
                      }`}
                  >
                    radio_button_checked
                  </span>
                </button>
              );
            })}
          </div>
          <button
            className="button-arrow"
            onClick={() => {
              updateIndex(activeIndex + 1);
            }}
          >
            <span className="material-symbols-outlined">arrow_forward_ios</span>
          </button>
        </div>
    </div>
  );
};

export default Depoimentos;
