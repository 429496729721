import React from 'react';
import './iconesNavbar.css';

import whatsIcon from '../../assets/whats-icon-navbar.webp';
import instaIcon from '../../assets/instagram.webp';
import { whatsLink, instaLink } from '../../containers/Contato/contatos';

const IconesNavbar = () => {
  return (
    <div className="site__navbar-icons">
      <a href={instaLink} target="_blank" rel="noreferrer"><img src={instaIcon} alt="Instagram Link" /></a>
      <a href={whatsLink} target="_blank" rel="noreferrer"><img src={whatsIcon} alt="WhatsApp Link" /></a>
      {/* <a href={`mailto:${email}?subject=${subject}`} target="_self"><img src={emailIcon} alt="E-mail Link" /></a> */}
    </div>
  )
}

export default IconesNavbar;
