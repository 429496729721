import React, { useState, useEffect } from 'react';
import './navbar.css';

import { IconesNavbar, Idioma, Logo, Menu, MenuLateral } from '../../components';

import simbolo from '../../assets/logo-simbolo.webp';
import nome from '../../assets/logo-nome.webp';

const Navbar = () => {
  const [applyShadow, setApplyShadow] = useState(false);
  const [removeShadow, setRemoveShadow] = useState(false);

  useEffect(() => {
    const handleScrollBlur = () => {
      const scrollPosition = window.scrollY;
      const headerHeight = 0;

      if (scrollPosition > headerHeight && !applyShadow) {
        setApplyShadow(true);
        setRemoveShadow(false);
      } else if (scrollPosition <= headerHeight && applyShadow) {
        setApplyShadow(false);
        setRemoveShadow(true);
      }
    };

    window.addEventListener('scroll', handleScrollBlur);
    return () => {
      window.removeEventListener('scroll', handleScrollBlur);
    };
  }, [applyShadow, removeShadow]);

  return (
    <div className={`site__navbar ${applyShadow ? 'navbar-shadow' : ''} ${removeShadow ? 'navbar-no-shadow' : ''}`}>
      <div className="site__navbar-links">
        <div className="site__navbar-links_logo">
          <a href="#home">
            <Logo />
          </a>
        </div>

        <div className="site__navbar-links_container">
          <Menu />
        </div>

        <div className="site__navbar-links_icons">
          <IconesNavbar />
        </div>

        <div className="site__navbar-links_idiomas">
          <Idioma />
        </div>
      </div>


      <div className="site__navbar-links_mobile">
        <div className="mobile-container-top">
          <div className="site__navbar-links_logo-mobile">
            <a href="#home">
              <Logo />
            </a>
          </div>

          <div className="mobile-logo-simbolo">
            <div className="mobile-logo-simbolo-img">
              <a href="#home">
                <img src={simbolo} alt="Logo" />
              </a>
            </div>

            <a href="#home">
              <img src={nome} alt="Logo" />
            </a>
          </div>

          <div className="site__navbar-links_icons-medio">
            <IconesNavbar />
          </div>

          <div className="site__navbar-links_idiomas-mobile">
            <Idioma />
          </div>
        </div>

        <div className="mobile-container-bottom">
          {/* <div className="mobile-logo-nome">
            <a href="#home">
              <img src={nome} alt="Logo" />
            </a>
          </div> */}

          <div className="mobile-bottom-links">
            {/* <div className="site__navbar-links_icons-mobile">
              <IconesNavbar />
            </div> */}
            <div className="site__navbar-links_idiomas-mobile2">
              <Idioma />
            </div>
          </div>
        </div>
      </div>

      <div className="site__navbar-menu">
        <MenuLateral />
      </div>
    </div>
  )
}

export default Navbar;
