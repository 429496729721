import React from 'react';
import './duvida.css';

const Duvida = ({ titulo, texto, expanded, onClick, isFirstItem }) => {
  return (
    <div className={`site__duvida ${expanded ? 'active' : ''}`} onClick={onClick}>
      <div className="site__duvida-content">
        <div className={`site__duvida-content_title ${isFirstItem ? 'first-item-style' : ''}`}>
          <h3>{titulo}</h3>
        </div>
        <div className="site__duvida-content_text">
          <div className="site__duvida-content_text-container">
            <p>{texto}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Duvida;