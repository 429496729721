import React, { useState, useEffect } from 'react';
import './botaoFixoWhats.css';

import whatsIcon from '../../assets/whatsapp-icon.webp';

import { useTranslation } from "react-i18next";

const BotaoFixoWhats = () => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const sobreSection = document.getElementById('sobre');
            const sobreRect = sobreSection.getBoundingClientRect();

            const isSobreVisible = sobreRect.top < window.innerHeight && sobreRect.bottom >= 0;
            const isBelowSobre = sobreRect.top < window.innerHeight && sobreRect.bottom < 0;

            setIsVisible(isSobreVisible || isBelowSobre);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const openWhatsApp = () => {
        const whatsappLink = 'https://api.whatsapp.com/send?phone=5511955885365&text=Oi,%20Kalmy,%20gostaria%20de%20marcar%20uma%20sess%C3%A3o%20de%20psicoterapia%20com%20voc%C3%AA.';
        window.open(whatsappLink, '_blank');
    };

    const buttonClass = isVisible ? 'whatsapp-button' : 'whatsapp-button whatsapp-button-fade';

    return (
        <div className={buttonClass} onClick={openWhatsApp}>
            <img src={whatsIcon} alt={t('alt.botaoWhats')} />
        </div>
  )
}

export default BotaoFixoWhats;
