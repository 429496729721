import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './idioma.css';

import brasil from '../../assets/brasil.webp';
import england from '../../assets/england.webp';

const Idioma = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const changeLanguage = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
  };

  return (
    <div className="site__language">
      <img
        src={brasil}
        alt="Português"
        width={25}
        height={25}
        className={selectedLanguage === 'pt' ? 'active' : ''}
        onClick={() => changeLanguage('pt')}
      />
      <img
        src={england}
        alt="English"
        width={25}
        height={25}
        className={selectedLanguage === 'en' ? 'active' : ''}
        onClick={() => changeLanguage('en')}
      />
    </div>
  );
};

export default Idioma;
