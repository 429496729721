import React from 'react';
import './especialidades.css';

import { Especialidade } from '../../components';
import { espec1, espec2, espec4, espec5, espec6 } from './imgImports';

import { BotaoWhats } from '../../components';
import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Especialidades = () => {
  const { t } = useTranslation();
  // <h2><i>{t('sobre.titulo')}</i></h2>

  return (
    <div className="site__especialidades section__padding" id="especialidades">
      <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={1}>
        <h2>{t('especialidade.titulo')}</h2>
      </AnimationOnScroll>

      <div className="site__especialidades-container linha-superior">
        <Especialidade
          className="grid-item"
          titulo={t('especialidade.titulo1')}
          img={espec1}
          alt={t('especialidade.alt1')}
          topico1={t('especialidade1.topico1')}
          topico2={t('especialidade1.topico2')}
          topico3={t('especialidade1.topico3')}
          topico4={t('especialidade1.topico4')}
          topico5={t('especialidade1.topico5')}
          topico6={t('especialidade1.topico6')}
          topico7={t('especialidade1.topico7')}
        />

        <Especialidade
          className="grid-item"
          titulo={t('especialidade.titulo2')}
          img={espec2}
          alt={t('especialidade.alt2')}
          topico1={t('especialidade2.topico1')}
          topico2={t('especialidade2.topico2')}
          topico3={t('especialidade2.topico3')}
          topico4={t('especialidade2.topico4')}
          topico5={t('especialidade2.topico5')}
          topico6={t('especialidade2.topico6')}
          topico7={t('especialidade2.topico7')}
        />

        <Especialidade
          className="grid-item"
          titulo={t('especialidade.titulo4')}
          img={espec4}
          alt={t('especialidade.alt4')}
          topico1={t('especialidade4.topico1')}
          topico2={t('especialidade4.topico2')}
          topico3={t('especialidade4.topico3')}
          topico4={t('especialidade4.topico4')}
          topico5={t('especialidade4.topico5')}
          topico6={t('especialidade4.topico6')}
          topico7={t('especialidade4.topico7')}
        />
      </div>

      <div className="site__especialidades-container linha-inferior">
        <Especialidade
          className="grid-item"
          titulo={t('especialidade.titulo5')}
          img={espec5}
          alt={t('especialidade.alt5')}
          topico1={t('especialidade5.topico1')}
          topico2={t('especialidade5.topico2')}
          topico3={t('especialidade5.topico3')}
          topico4={t('especialidade5.topico4')}
          topico5={t('especialidade5.topico5')}
          topico6={t('especialidade5.topico6')}
          topico7={t('especialidade5.topico7')}
        />

        <Especialidade
          className="grid-item"
          titulo={t('especialidade.titulo6')}
          img={espec6}
          alt={t('especialidade.alt6')}
          topico1={t('especialidade6.topico1')}
          topico2={t('especialidade6.topico2')}
          topico3={t('especialidade6.topico3')}
          topico4={t('especialidade6.topico4')}
          topico5={t('especialidade6.topico5')}
          topico6={t('especialidade6.topico6')}
          topico7={t('especialidade6.topico7')}
        />

      </div>

      <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={1}>
        <div className="site__espec-btn">
          <BotaoWhats message={t('especialidade.botao')} />
        </div>
      </AnimationOnScroll>
    </div>
  )
}

export default Especialidades;