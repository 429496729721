import React from 'react';
import './botaoWhats.css';

import { FaWhatsapp } from 'react-icons/fa';
import { whatsLink } from '../../containers/Contato/contatos';

const BotaoWhats = ({ message }) => {
    return (
        <a href={whatsLink} target="_blank" rel="noopener noreferrer">
            <div className="site__whatsBtn">
                <button type="button">
                    {message}
                    <span className="site__whatsBtn-icon">
                        <FaWhatsapp />
                    </span>
                </button>
            </div>
        </a>
    )
}

export default BotaoWhats;
