import React, { useState } from 'react';
import './duvidas.css';

import { BotaoWhats, Duvida } from '../../components';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useTranslation } from 'react-i18next';

const Duvidas = () => {
  const { t } = useTranslation();

  const [expandedInfoIndex, setExpandedInfoIndex] = useState(null);
  const titulos = ["duvidas.titulo1", "duvidas.titulo2", "duvidas.titulo3", "duvidas.titulo4", "duvidas.titulo5", "duvidas.titulo6", "duvidas.titulo7", "duvidas.titulo8", "duvidas.titulo9", "duvidas.titulo10", "duvidas.titulo11", "duvidas.titulo12", "duvidas.titulo13"];
  const textos = ["duvidas.texto1", "duvidas.texto2", "duvidas.texto3", "duvidas.texto4", "duvidas.texto5", "duvidas.texto6", "duvidas.texto7", "duvidas.texto8", "duvidas.texto9", "duvidas.texto10", "duvidas.texto11", "duvidas.texto12", "duvidas.texto13"];

  const handleInfoClick = (index) => {
    if (index === expandedInfoIndex) {
      setExpandedInfoIndex(null);
    } else {
      setExpandedInfoIndex(index);
    }
  };

  return (
    <div className="site__duvidas section__padding" id="faq">
      <AnimationOnScroll animateIn="slide-in-right" duration={1} animateOnce="true">
        <h2>{t('duvidas.titulo')}</h2>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="slide-in-left" duration={1} animateOnce="true">
        <div className="site__duvidas-container">
          {titulos.map((titulo, index) =>
            <Duvida
              key={index}
              titulo={t(titulo)}
              texto={t(textos[index])}
              expanded={index === expandedInfoIndex}
              isFirstItem={index === 0}
              onClick={() => handleInfoClick(index)}
            />
          )}
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="fade-in-bottom" duration={1} animateOnce="true">
        <div className="site__duvidas-btn">
          <BotaoWhats message={t('duvidas.botao')} />
        </div>
      </AnimationOnScroll>
    </div>
  )
}

export default Duvidas;