import React from 'react';
import './experienciaAcademica.css';

import { ExpAcadDir, ExpAcadEsq, IconeAcad } from '../../components';
import iconeLinha from '../../assets/iconeExpAcad.webp';

import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const ExperienciaAcademica = () => {
  const { t } = useTranslation();

  return (
    <div className="site__acad section__padding">
      <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={1}>
        <div className="site__acad-titulo">
          {/* <h1>{t('expAcad.titulo')}</h1> */}
          <h2>{t('expAcad.subtitulo')}</h2>
        </div>
      </AnimationOnScroll>

      {/* <IconeAcad src={iconeLinha} alt={t('alt.iconeAcad')} /> */}
      {/* <div className="linha-container"> */}
      <div className="linha-vertical-acad" />
        {/* <div className="topo">
          <IconeAcad src={iconeLinha} alt={t('alt.iconeAcad')} />
        </div>

        <div className="parte-inferior">
          <IconeAcad src={iconeLinha} alt={t('alt.iconeAcad')} />
        </div> */}
      {/* </div> */}


      <div className="item-esquerda-acad">
        <div className="card-acad">
          <ExpAcadEsq titulo={t('expAcad.titulo1')} descricao={t('expAcad.descricao1')} />
          <IconeAcad src={iconeLinha} alt={t('alt.iconeAcad')} />
        </div>
      </div>

      <div className="item-direita-acad">
        <div className="card-acad">
          <ExpAcadDir titulo={t('expAcad.titulo2')} descricao={t('expAcad.descricao2')} />
          <IconeAcad src={iconeLinha} alt={t('alt.iconeAcad')} />
        </div>
      </div>

      <div className="item-esquerda-acad">
        <div className="card-acad">
          <ExpAcadEsq titulo={t('expAcad.titulo3')} descricao={t('expAcad.descricao3')} />
          <IconeAcad src={iconeLinha} alt={t('alt.iconeAcad')} />
        </div>
      </div>

      <div className="item-direita-acad">
        <div className="card-acad">
          <ExpAcadDir titulo={t('expAcad.titulo4')} descricao={t('expAcad.descricao4')} />
          <IconeAcad src={iconeLinha} alt={t('alt.iconeAcad')} />
        </div>
      </div>

      <div className="item-esquerda-acad">
        <div className="card-acad">
          <ExpAcadEsq titulo={t('expAcad.titulo5')} descricao={t('expAcad.descricao5')} />
          <IconeAcad src={iconeLinha} alt={t('alt.iconeAcad')} />
        </div>
      </div>

      <div className="item-direita-acad">
        <div className="card-acad">
          <ExpAcadDir titulo={t('expAcad.titulo6')} descricao={t('expAcad.descricao6')} />
          <IconeAcad src={iconeLinha} alt={t('alt.iconeAcad')} />
        </div>
      </div>

      <div className="item-esquerda-acad">
        <div className="card-acad">
          <ExpAcadEsq titulo={t('expAcad.titulo7')} descricao={t('expAcad.descricao7')} />
          <IconeAcad src={iconeLinha} alt={t('alt.iconeAcad')} />
        </div>
      </div>

      <div className="item-direita-acad">
        <div className="card-acad">
          <ExpAcadDir titulo={t('expAcad.titulo8')} descricao={t('expAcad.descricao8')} />
          <IconeAcad src={iconeLinha} alt={t('alt.iconeAcad')} />
        </div>
      </div>
    </div>
  )
}

export default ExperienciaAcademica;
