import React from 'react';
import './expProfEsqAlternate.css';

import { AnimationOnScroll } from 'react-animation-on-scroll';

// import meio from '../../assets/expProf-icon-esq.webp';

const ExpProfEsqAlternate = ({ titulo, descricao }) => {
  return (
    <AnimationOnScroll animateIn="slide-in-right" duration={1} animateOnce="true" >
      <div className="site__expProfEsqAlt">
        <div className="site__expProfEsqAlt-seta_mobile"/>

        <div className="site__expProfEsqAlt-content">
          <h6>{titulo}</h6>
          <p>{descricao}</p>
        </div>

        <div className="site__expProfEsqAlt-seta"/>
      </div>
    </AnimationOnScroll>
  )
}

export default ExpProfEsqAlternate;
