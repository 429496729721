import React from 'react';
import './capa.css';

import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import { BotaoWhats } from '../../components';
// import capaImg from '../../assets/capa-img.webp'

const Capa = () => {
  const { t } = useTranslation();

  return (
    <div className="site__capa capa__padding" id="home">
      <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={1}>
        <div className="site__capa-content">
          <div className="site__capa-content_text">
            <p className="capa-nome">{t('capa.nome')}</p>
            <h3><i>"{t('capa.apresentacao')}"</i></h3>
            <p className="capa-sobre">{t('capa.sobre')}</p>
            {/* <p className="capa-sobre">{t('capa.modalidade')}</p>
            <p className="capa-sobre">{t('capa.descricao')}</p>
            <p className="capa-sobre">{t('capa.aqui')}</p> */}
          </div>

          <div className="site__capa-content_btn">
            <BotaoWhats message={t('botao.agende')} />
          </div>
        </div>
      </AnimationOnScroll>

      {/* <AnimationOnScroll animateIn="fade-in-bottom" animateOnce="true" duration={1} offset={1000}>
        <div className="site__capa-image">
          <img src={capaImg} alt="Foto do psicólogo Kalmy Vieira." style={{ display: 'block', height: '100%', margin: 0 }} />
        </div>
      </AnimationOnScroll> */}
    </div>
  )
}

export default Capa;
