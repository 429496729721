import React from 'react';
import './contato.css'

import { whatsFooter, instagramFooter } from './imports';
import { whatsLink, instaLink } from './contatos';

const Contato = () => {
  return (
    <div className="site__contatos">
      <a href={instaLink} target="_blank" rel="noreferrer">
        <img src={instagramFooter} alt="Instagram Link" />
      </a>

      <a href={whatsLink} target="_blank" rel="noreferrer">
        <img src={whatsFooter} alt="WhatsApp Link" />
      </a>

      {/* <a href={`mailto:${email}?subject=${subject}`} target="_self">
        <img src={emailFooter} alt="Email Link" />
      </a> */}
    </div>
  )
}

export default Contato;
