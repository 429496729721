import React from 'react';
import './App.css';

import { BotaoFixoWhats, Navbar } from './components';
import { Capa, Depoimentos, Duvidas, Especialidades, ExperienciaAcademica, ExperienciaProfissional, Rodape, Sessao, Sobre } from './containers';

import { useTranslation } from "react-i18next";

// import TagManager from 'react-gtm-module';
// const tagManagerArgs = {
//   gtmId: 'G-2H64MMWGQY'
// };
// TagManager.initialize(tagManagerArgs);

const App = () => {
  const { t } = useTranslation();

  document.title = t("tituloPagina");

  return (
    <div className="App">
      <Navbar />
      <Capa />
      <BotaoFixoWhats />
      <Sobre />
      <Sessao />
      <Especialidades />
      <ExperienciaProfissional />
      <ExperienciaAcademica />
      <Duvidas />
      <Depoimentos />
      <Rodape />
    </div>
  )
}

export default App;
